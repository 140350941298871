<template>
    <v-container :fill-height="loading" class="mb-3">
        <AdBTWR v-if="repeater.ID !== null && !user.isAdFree"/>

        <v-row justify="center">
            <v-col cols="12" v-if="alert.state">
                <v-alert :type="alert.type" class="mt-5" border="left" elevation="3" prominent>
                    <span v-html="alert.text"></span>
                </v-alert>
            </v-col>
            <v-col cols="12" xl="10">
                <RepeaterDetails
                    :repeater="repeater"                    
                    :authUser="user"
                    :loading="loading"                    
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import config from '../config';
    import axios from 'axios';
    import RepeaterDetails from '@/components/Repeater.vue';
    import AdBTWR from '@/components/ads/BTWR.vue';
    import {useTitle} from '@vueuse/core';

    export default {
        name: 'Repeater',

        components: {
            AdBTWR,
            RepeaterDetails
        },

        props: ['user'],

        data: () => ({
            loading: false,
            alert: {
                state: false,
                type: 'info',
                text: ''
            },
            repeater: {
                ID: null,
                Name: null,
                Type: null,
                Owner: null,
                Frequency: null,
                'PL In': null,
                'PL Out': null
            },
            comments: []
        }),

        methods: {
            fetchRepeater() {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                this.loading = true;

                axios
                    .get(config.API_LOCATION + '/repeater/' + this.$route.params.id, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.repeater = response.data;
                        this.fetchUser(this.repeater.Owner);

                        useTitle(`${this.repeater.Name.trim()}: ${this.repeater.Location.trim()}, ${this.repeater.State} - myGMRS.com`);
                    })
                    .catch(err => {
                        if (err.response) {
                            switch (err.response.status) {
                                case 401:
                                    this.$emit('unauthorized');
                                    break;
                                case 404:
                                    this.alert.state = true;
                                    this.alert.type = 'warning';
                                    this.alert.text = 'The requested repeater does not exist.';
                                    break;
                            }
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },

            fetchUser(username) {
                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/user/' + username, {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.repeater.Owner = response.data;
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                    });
            },
        },
        mounted() {
            this.fetchRepeater();
        }
    }
</script>
